@import "./animate.min.css";
@import "./bootstrap.min.css";
@import "./fontawesome.min.css";
@import "./responsive.css";
@import "./slick.css";
@import "./solid.min.css";
@import "./style-themes.css";

/* 1. Theme default css */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
body {
  font-family: "Outfit", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #a4b4c3;
  font-style: normal;
  background-color: #030b15;
  line-height: 1.75;
}
body.white-background {
  background-color: #ffffff;
}
img,
.img {
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.fix {
  overflow: hidden;
}
a,
button {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
}
a:focus,
.btn:focus,
button:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
  color: #00c4f4;
  text-decoration: none;
}
a,
button {
  color: #00c4f4;
  outline: medium none;
  text-decoration: none;
}
.btn:focus,
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  margin-top: 0px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 35px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
ul {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;
}
p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.75;
  color: #727885;
  margin-bottom: 15px;
}
hr {
  border-bottom: 1px solid #eceff8;
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}
label {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
*::-moz-selection {
  background: #00c4f4;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: #00c4f4;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #00c4f4;
  color: #fff;
  text-shadow: none;
}
*::-moz-placeholder {
  color: #00c4f4;
  font-size: 14px;
  opacity: 1;
}
*::placeholder {
  color: #555555;
  font-size: 14px;
  opacity: 1;
}
.theme-overlay {
  position: relative;
}
.theme-overlay::before {
  background: #1696e7 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}
.separator {
  border-top: 1px solid #f2f2f2;
}

/* Bootstrap 5 */
.container {
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  --bs-gutter-x: 30px;
}
.row.g-0 {
  --bs-gutter-x: 0;
}
.gutter-y-30 {
  --bs-gutter-y: 30px;
}

.MuiCheckbox-root {
  color: #727cf5 !important;
}

.text-info {
  color: #007bff !important;
}
.text-success {
  color: #10b759 !important;
}
.text-warning {
  color: #fbbc06 !important;
}
/* button style */
.btn-otline-primary:hover {
  border-color: #727cf5 !important;
  color: #727cf5 !important;
}
.btn-otline-primary-ac {
  border-color: #727cf5 !important;
  color: #727cf5 !important;
}
.btn-line {
  font-size: 12px !important;
  line-height: 1;
  padding: 9px 7px;
  background-color: #0c1427 !important;
  margin-right: 10px;
  min-width: 90px;
  cursor: default;
}
.btn-line-success {
  border: solid 1px #10b759 !important;
  color: #10b759 !important;
}
.color-primary {
  color: #727cf5 !important;
}
.btn-line-primary {
  border: solid 1px #727cf5 !important;
  color: #727cf5 !important;
}
.btn-line-primary-hover:hover {
  background-color: #727cf5 !important;
  color: #fff !important;
}
.btn-line-info-hover:hover {
  background-color: #007bff !important;
  color: #fff !important;
}
.btn-line-danger {
  border: solid 1px #ff3366 !important;
  color: #ff3366 !important;
}
.btn-line-danger-hover:hover {
  background-color: #ff3366 !important;
  color: #333 !important;
}
.btn-line-success-hover:hover {
  background-color: #10b759 !important;
  color: #fff !important;
}
.btn-line-warning {
  border: solid 1px #fbbc06 !important;
  color: #fbbc06 !important;
}
.btn-line-info {
  border: solid 1px #007bff !important;
  color: #007bff !important;
}
.btn {
  user-select: none;
  -moz-user-select: none;
  background: #040e18;
  border-radius: 70px;
  border: 2px solid transparent;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1;
  margin-bottom: 0;
  padding: 25px 50px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.4s 0s;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: 0px 6px 22px rgba(6, 34, 51, 0.22);
  background-clip: padding-box;
  position: relative;
}
.btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: -2px;
  background: linear-gradient(
    90deg,
    rgba(0, 196, 244, 1) 0%,
    rgba(4, 12, 21, 1) 49%,
    rgba(0, 196, 244, 1) 100%
  );
  border-radius: 70px;
  transition: all 0.4s ease 0s;
  z-index: -1;
}
.btn:hover {
  border-color: #00c4f4;
  color: #00c4f4;
}
.btn.btn-one {
  background: #00c4f4 !important;
  border-radius: 5px;
  padding: 25px 42px;
  border: none;
  margin-top: 20px;
}
.btn.btn-one::after {
  display: none;
}
.btn.btn-one:hover {
  color: #fff;
  background: #564dca !important;
}
.btn.btn-two {
  background: #564dca !important;
  border-radius: 5px;
  padding: 25px 42px;
  border: none;
}
.btn.btn-green {
  background: #2ecc71 !important;
  border-radius: 5px;
  padding: 25px 42px;
  border: none;
}
.btn.btn-green-two {
  background: #333 !important;
  border-radius: 5px;
  padding: 20px 42px;
  border: 1px solid #333 !important;
}
.btn.btn-gray {
  background: gray !important;
  border-radius: 5px;
  padding: 25px 42px;
  border: none;
}
.btn.btn-tree {
  background: #333 !important;
  border-radius: 5px;
  padding: 25px 42px;
  border: none;
}
.btn.btn-four {
  background: #333 !important;
  border-radius: 5px;
  padding: 25px 40px;
  border: 1px solid #333 !important;
}
.btn.btn-two::after {
  display: none;
}
.btn.btn-tree::after {
  display: none;
}
.btn.btn-four::after {
  display: none;
}
.btn.btn-green::after {
  display: none;
}
.btn.btn-gray::after {
  display: none;
}
.btn.btn-two:hover {
  color: #fff;
  background: #00c4f4 !important;
}
.btn.btn-tree:hover {
  color: #fff;
  background: #564dca !important;
}
.btn.btn-four:hover {
  color: #f41b51;
  border: 1px solid #f41b51 !important;
}
.btn.btn-green:hover {
  color: #333;
}
.btn.btn-green-two:hover {
  color: #2ecc71;
  border: 1px solid #2ecc71 !important;
}
.btn.btn-gray:hover {
  color: #333;
}
.btn-small {
  padding: 15px 25px !important;
  font-size: 13px;
}
.btn-small-s {
  padding: 13px 15px !important;
  font-size: 14px;
}
.btn-small-xs {
  padding: 10px 15px !important;
  font-size: 10px;
}
.btn-small-xxs {
  padding: 7px 15px !important;
  font-size: 10px;
}
.breadcrumb > .active {
  color: #888;
}

/* scrollUp */
.scroll-top {
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  bottom: 105%;
  right: 50px;
  font-size: 16px;
  border-radius: 50%;
  z-index: 99;
  color: #3d3d3d;
  text-align: center;
  cursor: pointer;
  background: #dcbc95;
  transition: 1s ease;
  border: none;
}
.scroll-top.open {
  bottom: 30px;
}
.scroll-top::after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
.scroll-top:hover {
  background: #a3a3a3;
}

/* 2. Header */
.custom-container {
  max-width: 1630px;
}
.custom-container-two {
  max-width: 1700px;
}
.custom-container-three {
  max-width: 1520px;
}
.custom-container-four {
  max-width: 1200px;
}
.transparent-header {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 9;
  height: auto;
}
.menu-area {
  border-bottom: 1px solid;
}
.menu-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.navbar-wrap {
  display: flex;
  flex-grow: 1;
}
.navbar-wrap ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 140px;
}
.navbar-wrap > ul > li {
  display: block;
  position: relative;
  margin-right: 65px;
}
.navbar-wrap > ul > li > a {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  padding: 45px 0;
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  letter-spacing: 1px;
}
.navbar-wrap > ul > li:last-child {
  margin-right: 0;
}
.navbar-wrap > ul > li > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #00c4f4;
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.navbar-wrap > ul > li.active > a::before,
.navbar-wrap > ul > li > a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.navbar-wrap > ul > li.active > a,
.navbar-wrap > ul > li:hover > a {
  color: #fff;
}
.main-menu .navigation li.menu-item-has-children .dropdown-btn {
  display: none;
}
.header-action > ul {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.header-action > ul > li {
  position: relative;
  margin-left: 65px;
  padding-left: 65px;
}
.header-action > ul > li:first-child {
  margin-left: 0;
  padding-left: 0;
}
.header-action > ul > li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 28px;
  background: #ffffff;
  opacity: 0.2;
}
.header-action > ul > li:first-child::before {
  display: none;
}
.header-lang {
  position: relative;
}
.header-lang .selected-lang {
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
  letter-spacing: 1px;
}
.header-lang .selected-lang::after {
  content: "\f107";
  display: block;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: #00c4f4;
  margin-left: 10px;
}
.header-lang .lang-list {
  position: absolute;
  left: -10px;
  top: calc(100% + 25px);
  background: #0b1d33;
  z-index: 3;
  padding: 10px 0;
  border-radius: 6px;
  box-shadow: 0px 30px 70px 0px rgba(40, 44, 49, 0.15);
  min-width: 100px;
  visibility: hidden;
  opacity: 0;
  transform-origin: top center;
  transform: perspective(400px) rotateX(-45deg);
  transition: all 0.3s ease-out 0s;
}
.header-lang:hover .lang-list {
  visibility: visible;
  opacity: 1;
  transform: perspective(400px) rotateX(0deg);
}
.header-lang .lang-list li {
  margin-bottom: 5px;
  line-height: 1;
}
.header-lang .lang-list li:last-child {
  margin-bottom: 0;
}
.header-lang .lang-list li a {
  display: block;
  font-size: 16px;
  color: #fff;
  padding: 5px 15px 5px 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.header-lang .lang-list li a:hover {
  color: #00c4f4;
}
.header-btn .btn {
  font-size: 14px;
  padding: 20px 45px;
}
.navbar-wrap ul li .sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  min-width: 230px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  background: #0b1d33;
  margin: 0;
  transform: scale(1, 0);
  transform-origin: 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  padding: 18px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}
.navbar-wrap ul li .sub-menu .sub-menu {
  right: auto;
  left: 100%;
  top: 0;
}
.navbar-wrap ul li .sub-menu li {
  margin-left: 0;
  text-align: left;
  display: block;
}
.navbar-wrap ul li .sub-menu li a {
  padding: 9px 15px 9px 25px;
  line-height: 1.4;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
  font-size: 14px;
}
.navbar-wrap ul li .sub-menu li a:hover,
.navbar-wrap ul li .sub-menu li.active a {
  color: #00c4f4;
}
.navbar-wrap ul li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #030b15;
  -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  border-radius: 0;
}
.sticky-menu .navbar-wrap > ul > li > a {
  padding: 45px 0;
}
#header-fixed-height.active-height {
  display: block;
  height: 105px;
}

/* header-two */
.menu-area.menu-style-two {
  border-bottom: none;
  padding: 30px 0;
}
.menu-style-two .navbar-wrap > ul > li > a {
  padding: 40px 0;
}
.menu-style-two .navbar-wrap > ul > li > a::before {
  display: none;
}
.menu-style-two .header-btn .btn {
  background: #00c4f4;
  border-radius: 5px;
}
.menu-style-two .header-btn .btn::after {
  display: none;
}
.menu-style-two .header-btn .btn:hover {
  background: #564dca;
  border-color: #564dca;
  color: #fff;
}
.menu-area.menu-style-two.sticky-menu {
  padding: 0 0;
}
.menu-style-two .navbar-wrap > ul > li.active > a,
.menu-style-two .navbar-wrap > ul > li:hover > a {
  color: #00c4f4;
}

/* 3. Mobile-menu */
.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  display: none;
  color: #fff;
  margin-right: 30px;
  top: 15px;
}
.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  border-radius: 0px;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
}
.mobile-menu .navbar-collapse {
  display: block !important;
}
.mobile-menu .nav-logo {
  position: relative;
  padding: 30px 25px;
  text-align: left;
}
.mobile-menu-visible {
  overflow: hidden;
}
.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}
.mobile-menu .navigation li.current > a:before {
  height: 100%;
}
.menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  opacity: 0;
  visibility: hidden;
  background: #0b1d33;
}
.mobile-menu-visible .menu-backdrop {
  opacity: 0.8;
  visibility: visible;
}
.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #0b1d33;
  padding: 0px 0px;
  z-index: 5;
  box-shadow: -9px 0 14px 0px;
}
.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
}
.mobile-menu .close-btn {
  position: absolute;
  right: 15px;
  top: 28px;
  line-height: 30px;
  width: 35px;
  text-align: center;
  font-size: 20px;
  color: #00c4f4;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}
.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
}
.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid;
}
.mobile-menu .navigation:last-child {
  border-bottom: 1px solid;
}
.mobile-menu .navigation li > ul > li:first-child {
  border-top: 1px solid;
}
.mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 60px 10px 25px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: none;
  letter-spacing: 0.08em;
}
.mobile-menu .navigation li ul li > a {
  font-size: 15px;
  margin-left: 20px;
  text-transform: capitalize;
}
.mobile-menu .navigation li ul li ul li a {
  margin-left: 40px;
}
.mobile-menu .navigation li ul li ul li ul li a {
  margin-left: 60px;
}
.mobile-menu .navigation li > a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn {
  position: absolute;
  right: 15px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: #fff;
  background: #00c4f4;
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
  display: none;
}
.mobile-menu .social-links ul {
  display: flex;
  position: relative;
  text-align: center;
  padding: 30px 20px 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.mobile-menu .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 6px 10px;
}
.mobile-menu .social-links li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: #fff;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: 1px solid;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.mobile-menu .social-links li a:hover {
  border-color: #00c4f4;
  background: #00c4f4;
  color: #fff;
}
.menu-area .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 30px;
  cursor: pointer;
  line-height: 1;
  color: #000;
  display: none;
  margin-top: 3px;
}

/* 4. Banner */
.banner-bg {
  background-image: url(../images/banner_bg.jpg);
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 120px 0 0;
  overflow: hidden;
}
.banner-bg::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0.23deg,
    #030b15 5.68%,
    rgba(3, 11, 21, 0.42) 81.9%
  );
  z-index: -1;
}
.banner-shape-wrap img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.banner-shape-wrap img.img-one {
  top: 28%;
  left: -3%;
  animation: leftToRight 5s infinite linear;
}
.banner-shape-wrap img.img-one-one {
  top: 15%;
  left: 83%;
  animation: leftToRight 5s infinite linear;
}
.banner-shape-wrap img.img-two {
  left: auto;
  right: 8%;
  top: 20%;
}
.banner-shape-wrap img.img-two-two {
  left: 18%;
  top: 21%;
}
.banner-shape-wrap img.img-three {
  left: auto;
  right: -2%;
  top: auto;
  bottom: 14%;
  animation: alltuchtopdown 3s infinite linear;
}
.banner-shape-wrap img.img-three-three {
  left: 3%;
  top: 30%;
  bottom: 14%;
  animation: alltuchtopdown 3s infinite linear;
}
.banner-content img {
  margin-bottom: 25px;
}
.banner-content .title {
  font-size: 55px;
  margin-bottom: 45px;
  line-height: 1.4;
  letter-spacing: -0.01em;
}
.banner-content .title span {
  color: #00c4f4;
}
.banner-progress-wrap ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 50px;
}
.banner-progress-wrap ul li {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #00c4f4;
  display: inline-block;
  position: relative;
  padding-bottom: 25px;
}
.banner-progress-wrap ul li::before {
  content: "";
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 14px;
  background: #00c4f4;
}
.banner-progress-wrap ul li:nth-child(2) {
  color: #ff9700;
}
.banner-progress-wrap ul li:nth-child(3) {
  color: #12d176;
}
.banner-progress-wrap ul li:nth-child(2)::before {
  background: #ff9700;
}
.banner-progress-wrap ul li:nth-child(3)::before {
  background: #12d176;
}
.banner-progress-wrap {
  margin: 0 150px 70px;
}
.banner-progress-wrap .progress {
  height: 6px;
  background-color: #f2f2f2;
  border-radius: 0;
  overflow: inherit;
  margin-bottom: 25px;
  margin-top: 8px;
}
.banner-progress-wrap .progress .progress-bar {
  background-color: #00c4f4;
  position: relative;
  overflow: inherit;
}
.banner-progress-wrap .progress .progress-bar::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  z-index: 5;
  border: 6px solid #00c4f4;
}
.banner-progress-wrap .title {
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.banner-progress-wrap .title span {
  margin-left: auto;
}
.banner-countdown-wrap .title {
  font-size: 26px;
  margin-bottom: 15px;
  letter-spacing: -0.01em;
}
.banner-countdown-wrap .coming-time {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.banner-countdown-wrap .coming-time .time-count {
  min-width: 162px;
  min-height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  margin: 20px 17.5px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  background: #0b1d33;
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 5px;
  color: #a4b4c3;
  text-transform: capitalize;
}
.banner-countdown-wrap .coming-time .time-count span {
  font-size: 35px;
  font-weight: 600;
  color: #00c4f4;
  margin-bottom: 12px;
  display: block;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
}
.banner-countdown-wrap .coming-time .time-count.hour span {
  color: #ff9700;
}
.banner-countdown-wrap .coming-time .time-count.min span {
  color: #ff1d45;
}
.banner-countdown-wrap .coming-time .time-count.sec span {
  color: #12d176;
}
@keyframes alltuchtopdown {
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(-30px);
  }
  100% {
    transform: rotateX(0deg) translateY(0px);
  }
}
@keyframes leftToRight {
  0% {
    transform: rotateX(0deg) translateX(0px);
  }
  50% {
    transform: rotateX(0deg) translateX(50px);
  }
  100% {
    transform: rotateX(0deg) translateX(0px);
  }
}

/* banner-two */
.banner-area-two {
  position: relative;
  padding: 225px 0 0;
  z-index: 1;
}
.banner-bg-two {
  // background-image: url(../img/banner/banner_bg02.jpg);
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 164px);
  z-index: -1;
}
.banner-bg-two::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -40px;
  // background-image: url(../img/banner/banner_shape.svg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 722px;
  z-index: -1;
}
.banner-area-two .banner-content img {
  margin-bottom: 0;
}
.banner-area-two .banner-content {
  margin-bottom: 0;
}
.banner-area-two .banner-content .title {
  margin-bottom: 65px;
  line-height: 1.36;
  text-transform: none;
}
.banner-social-wrap {
  position: absolute;
  left: 100px;
  bottom: 90px;
}
.banner-social-wrap ul li {
  margin-bottom: 25px;
  border: 1px solid #f3f3f3;
  border-radius: 50px;
  width: 42px;
  display: flex;
  align-items: center;
}
.banner-social-wrap ul li.is-active {
  width: auto;
}
.banner-social-wrap ul li:last-child {
  margin-bottom: 0;
}
.banner-social-wrap ul li a {
  font-size: 15px;
  color: #081a39;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  transition: 0s;
}
.banner-social-wrap ul li span {
  color: #b5bac4;
  font-size: 14px;
  font-weight: 500;
  padding-right: 14px;
  display: none;
}
.banner-scroll {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 40px;
  bottom: 90px;
}
.banner-scroll span {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.515em;
  color: #081a39;
  transform: rotate(-90deg);
  margin-bottom: 120px;
  opacity: 0.4;
}

/* 5. Breadcrumb */
.breadcrumb-bg {
  // background-image: url(../img/bg/breadcrumb-bg.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 235px 0 150px;
}
.breadcrumb-bg::before {
  position: absolute;
  content: "";
  // background-image: url(../img/bg/breadcrumb_shape.png);
  background-repeat: repeat;
  background-position: center;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 19px;
}
.breadcrumb-content {
  text-align: center;
}
.breadcrumb-content .title {
  font-size: 65px;
  margin-bottom: 30px;
  letter-spacing: -0.01em;
  line-height: 1.15;
}
.breadcrumb-content .breadcrumb {
  display: flex;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
}
.breadcrumb-content .breadcrumb .breadcrumb-item {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.27;
  text-decoration: underline;
}
.breadcrumb-content .breadcrumb .breadcrumb-item:first-child {
  text-decoration: none;
}
.breadcrumb-content .breadcrumb .breadcrumb-item a {
  color: #fff;
}
.breadcrumb-content .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 15px;
  color: #ffffff;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-size: 17px;
  font-weight: 700;
  line-height: 1;
  opacity: 0.5;
  padding-top: 3px;
}
.breadcrumb-content .breadcrumb-item + .breadcrumb-item {
  padding-left: 15px;
}

/* 6. Countdown */
.countdown-area-two .countdown-wrap {
  background: #ffffff;
  box-shadow: 0px 34px 35px rgba(160, 171, 191, 0.21);
  border-radius: 20px;
  position: relative;
  text-align: center;
  padding: 55px 50px 60px;
}
.countdown-area-two .countdown-wrap::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 80%;
  background: #00c4f4;
  opacity: 0.08;
  border-radius: 20px;
  z-index: -1;
}
.countdown-area-two .countdown-wrap .title {
  color: #030b15;
  letter-spacing: -0.01em;
  font-size: 26px;
  margin-bottom: 35px;
  line-height: 1.88;
}
.ClassyCountdown-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0;
  column-gap: 115px;
  row-gap: 30px;
}
.ClassyCountdown-wrapper > div > div:not(:last-child) {
  display: block !important;
}
.ClassyCountdown-wrapper > div > div canvas {
  display: block;
  width: 100% !important;
  height: 100% !important;
}
.ClassyCountdown-value {
  display: block;
  line-height: 0;
}
.ClassyCountdown-value > div {
  display: block;
  text-align: center;
  line-height: 1;
  margin-bottom: 5px;
  font-size: 35px;
  font-weight: 600;
  color: #030b15;
  font-family: "Poppins", sans-serif;
  margin-top: -95px;
}
.ClassyCountdown-value > span {
  font-size: 14px;
  display: block;
  text-align: center;
  font-family: "Outfit";
  color: #030b15;
  font-weight: 500;
  line-height: 1;
}

/* 7. About */
.about-img {
  position: relative;
  padding-left: 120px;
}
.about-img img.img-two {
  position: absolute;
  left: 60px;
  top: 40px;
}
.about-content {
  margin-left: 70px;
}
.section-title .sub-title {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  letter-spacing: 0.09em;
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 25px;
}
.section-title .sub-title::after,
.section-title .sub-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: #00c4f4;
  border-radius: 50%;
}
.section-title .sub-title::after {
  left: auto;
  right: 0;
}
.section-title .title {
  font-size: 42px;
  margin-bottom: 0;
  letter-spacing: -0.01em;
}
.section-title .title span {
  color: #00c4f4;
}
.about-content p {
  margin-bottom: 40px;
  width: 73%;
  color: #a4b4c3;
}
.partner-wrap ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.partner-wrap ul li {
  width: 20%;
  height: 123px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -1px;
  margin-top: -1px;
  cursor: pointer;
}
.partner-wrap ul li img {
  opacity: 0.5;
  transition: 0.3s linear;
}
.partner-wrap ul li:hover img {
  opacity: 1;
}

/* about-two */
.about-area-two {
  padding: 130px 0;
  position: relative;
}
.section-title.section-title-two .sub-title {
  color: #564dca;
  letter-spacing: 0.02em;
  padding: 0;
  text-decoration: underline;
  margin-bottom: 18px;
}
.section-title.section-title-two .sub-title::before,
.section-title.section-title-two .sub-title::after {
  display: none;
}
.section-title.section-title-two .title {
  font-size: 45px;
  color: #030b15;
  line-height: 1.26;
}
.about-content-two p {
  color: #727885;
  margin-bottom: 20px;
  width: 80%;
}
.about-content-two .about-list {
  margin-bottom: 45px;
}
.about-content-two .about-list ul li {
  display: flex;
  align-items: baseline;
  color: #030b15;
  margin-bottom: 10px;
}
.about-content-two .about-list ul li:last-child {
  margin-bottom: 0;
}
.about-content-two .about-list ul li i {
  color: #564dca;
  margin-right: 10px;
}
.about-shape-wrap img {
  position: absolute;
}
.about-shape-wrap img.shape-one {
  left: 9%;
  bottom: 90px;
  animation: leftToRight 5s infinite linear;
}
.about-shape-wrap img.shape-two {
  right: 7%;
  bottom: 22%;
}
.rotateme {
  -webkit-animation-name: teamRotate;
  animation-name: teamRotate;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes teamRotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes teamRotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* 8. Choose */
.choose-area .row {
  margin: 0 -10px;
}
.choose-area .row [class*="col-"] {
  padding: 0 10px;
}
.choose-item {
  background: #030b15;
  border: 1px solid #121a23;
  border-radius: 15px;
  padding: 60px 40px;
  transition: 0.3s ease-in-out;
}
.choose-item:hover {
  border-color: transparent;
}
.choose-icon {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.07);
  border: 4px solid rgba(255, 255, 255, 0.06);
  border-radius: 50%;
  margin-bottom: 30px;
  transition: 0.3s ease-in-out;
}
.choose-item:hover .choose-icon {
  border-color: #00c4f4;
}
.choose-content .title {
  font-size: 22px;
  margin-bottom: 24px;
  font-weight: 500;
  text-transform: none;
  line-height: 1.27;
}
.choose-content p {
  margin-bottom: 0px;
  font-size: 15px;
  line-height: 22px;
  color: #a4b4c3;
}
.choose-area .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.choose-area .slide-progress {
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 0;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  background-image: linear-gradient(to right, #00c4f4, #00c4f4);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.4s ease-in-out;
  margin-top: 50px;
}

/* choose-two */
.choose-bg {
  // background-image: url(../images/chart_bg.jpg);
  background-size: cover;
  background-position: center;
  padding: 130px 0 218px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.choose-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  // background-image: url(../img/bg/choose_shape01.png);
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 25px;
}
.choose-bg::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  // background-image: url(../img/bg/choose_shape02.png);
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 19px;
}
.choose-item-two {
  margin-bottom: 30px;
}
.choose-icon-two {
  margin-bottom: 35px;
}
.choose-icon-two img {
  height: 48px;
}
.choose-item-two .choose-content {
  width: 77%;
}

/* 9. Chart */
.chart-inner {
  padding: 0 110px;
}
.chart-bg {
  // background-image: url(../img/bg/chart_bg.jpg);
  background-size: cover;
  background-position: center;
  padding: 130px 0;
}
.chart-content .nav-tabs {
  border-bottom: none;
  margin: 0 -20px;
  margin-bottom: 50px;
}
.chart-content .nav-tabs .nav-item {
  padding: 0 20px;
}
.chart-content .nav-tabs .nav-link {
  margin-bottom: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
  padding: 0;
  position: relative;
}
.chart-content .nav-tabs .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #00c4f4;
  transition: 0.3s linear;
  opacity: 0;
}
.chart-content .nav-tabs .nav-link.active::before {
  opacity: 1;
}
.chart-content {
  width: 72%;
}
.chart-content-inner .title {
  font-size: 55px;
  margin-bottom: 35px;
  letter-spacing: -0.01em;
  line-height: 1.2;
}
.chart-content-inner p {
  color: #a4b4c3;
  margin-bottom: 45px;
}
.chart-content-inner .btn {
  background: #00c4f4;
  min-width: 200px;
  padding: 21px 50px;
}
.chart-content-inner .btn:hover {
  color: #fff;
}
.chart-content-inner .btn::after {
  display: none;
}
.chart-wrap {
  background: #0b1d33;
  border-radius: 20px;
  padding: 60px 45px 60px;
  text-align: center;
}
.chart-wrap img {
  margin-bottom: 75px;
}
.chart-wrap ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.chart-wrap ul li {
  width: 50%;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  position: relative;
  padding-left: 30px;
  margin-bottom: 8px;
}
.chart-wrap ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #005f73;
}
.chart-wrap ul li:nth-child(2):before {
  background: #f72585;
}
.chart-wrap ul li:nth-child(3):before {
  background: #5dd400;
}
.chart-wrap ul li:nth-child(4):before {
  background: #ff9700;
}
.chart-wrap ul li:nth-child(5):before {
  background: #00c4f4;
}
.chart-wrap ul li:nth-child(6):before {
  background: #007ff4;
}

/* 10. Counter */
.counter-inner {
  background: #ffffff;
  box-shadow: 0px 34px 35px rgba(160, 171, 191, 0.21);
  border-radius: 20px;
  padding: 64px 75px 33px;
  position: relative;
  margin-top: -120px;
  margin-bottom: 10px;
}
.counter-inner::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  width: 80%;
  height: 80%;
  border-radius: 20px;
  background: #00c4f4;
  opacity: 0.08;
  z-index: -1;
}
.counter-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.counter-icon {
  width: 71px;
  flex: 0 0 71px;
  margin-right: 30px;
}
.counter-content .count {
  display: flex;
  align-items: center;
  color: #030b15;
  letter-spacing: -0.02em;
  font-size: 48px;
  line-height: 0.85;
  margin-bottom: 8px;
}
.counter-content p {
  margin-bottom: 0;
  color: #646580;
  line-height: 1.6;
}

/* 11. RoadMap */
.roadmap-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.roadmap-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(360deg, #0b1d33 0%, rgba(11, 29, 51, 0) 97.51%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  opacity: 0.8;
}
.roadmap-wrap {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1670px;
  overflow-x: auto;
}
.roadmap-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1670px;
  height: 8px;
  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  opacity: 0.1;
}
.roadmap-item {
  width: 345px;
  display: flex;
  flex-direction: column;
  flex: 0 0 345px;
}
.roadmap-title {
  display: inline-block;
  color: #2ecc71;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 7px;
  letter-spacing: 0.1em;
}
.roadmap-content .dot {
  content: "";
  position: absolute;
  left: -8px;
  top: 0;
  width: 16px;
  height: 16px;
  background: #2ecc71;
  border-radius: 50%;
}
.roadmap-content .dot::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
  background: #2ecc71;
  opacity: 0.1;
  border-radius: 50%;
}
.roadmap-content {
  position: relative;
  margin-left: 20px;
  padding-left: 17px;
  padding-top: 110px;
}
.roadmap-content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: #2ecc71;
}
.roadmap-content .title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.roadmap-content p {
  margin-bottom: 0;
  color: #a4b4c3;
}
.roadmap-item:nth-child(even) {
  flex-direction: column-reverse;
  margin-bottom: 165px;
  width: 400px;
  flex: 0 0 400px;
}
.roadmap-item:nth-child(even) .roadmap-content .dot {
  top: auto;
  bottom: 0;
}
.roadmap-item:nth-child(even) .roadmap-title {
  margin-top: 22px;
  margin-bottom: 0;
}
.roadmap-item:nth-child(even) .roadmap-content {
  padding-bottom: 110px;
  padding-top: 0;
}
.roadmap-item:not(:first-child) {
  margin-left: -120px;
}
.roadmap-item:nth-child(odd) {
  margin-top: 165px;
  margin-left: -180px;
}
.roadmap-item:nth-child(1) {
  margin-left: 100px;
  width: 288px;
  flex: 0 0 auto;
}
.roadmap-item:last-child {
  width: 290px;
  flex: 0 0 auto;
}
.bt-roadmap-item-2 .roadmap-content::before,
.bt-roadmap-item-2 .dot,
.bt-roadmap-item-2 .dot::before {
  background: #727cf5 !important;
}
.bt-roadmap-item-3 .roadmap-content::before,
.bt-roadmap-item-3 .dot,
.bt-roadmap-item-3 .dot::before {
  background: #ff4581;
}
.bt-roadmap-item-5 .roadmap-content::before,
.bt-roadmap-item-5 .dot,
.bt-roadmap-item-5 .dot::before {
  background: #007ff4;
}
.bt-roadmap-item:nth-child(5) .roadmap-content::before,
.bt-roadmap-item:nth-child(5) .dot,
.bt-roadmap-item:nth-child(5) .dot::before {
  background: #007ff4;
}
.bt-roadmap-item-4 .roadmap-content::before,
.bt-roadmap-item-4 .dot,
.bt-roadmap-item-4 .dot::before {
  background: #ff9700;
}
.bt-roadmap-item:nth-child(7) .roadmap-content::before,
.bt-roadmap-item:nth-child(7) .dot,
.bt-roadmap-item:nth-child(7) .dot::before {
  background: #ff4581;
}
.bt-roadmap-item-2 .roadmap-title {
  color: #727cf5 !important;
}
.bt-roadmap-item-3 .roadmap-title {
  color: #ff4581 !important;
}
.bt-roadmap-item:nth-child(4) .roadmap-title {
  color: #007ff4;
}
.bt-roadmap-item-5 .roadmap-title {
  color: #007ff4 !important;
}
.bt-roadmap-item-4 .roadmap-title {
  color: #ff9700 !important;
}
.bt-roadmap-item:nth-child(7) .roadmap-title {
  color: #ff4581;
}

/* roadmap-two */
.roadmap-wrap-two {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 50px;
}
.roadmap-wrap-two:last-child {
  margin-bottom: 0;
}
.roadmap-wrap-two::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  height: 8px;
  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  opacity: 0.1;
}
.roadmap-wrap-two .roadmap-content .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #030b15;
  line-height: 1.62;
}
.roadmap-wrap-two .roadmap-content p {
  color: #727885;
}
.roadmap-wrap-two .roadmap-content {
  padding-top: 75px;
}
.roadmap-wrap-two .roadmap-item:nth-child(odd) {
  margin-top: 0;
  margin-left: 0;
}
.roadmap-wrap-two .roadmap-item:not(:first-child) {
  margin-left: 0;
}
.roadmap-wrap-two .roadmap-item:nth-child(even) .roadmap-content {
  padding-bottom: 0;
  padding-top: 75px;
}
.roadmap-wrap-two .roadmap-item:nth-child(even) {
  flex-direction: column;
  margin-bottom: 30px;
  flex: 0 0 400px;
  width: 400px;
}
.roadmap-wrap-two .roadmap-item:nth-child(even) .roadmap-content .dot {
  top: 0;
  bottom: auto;
}
.roadmap-wrap-two .roadmap-item:nth-child(even) .roadmap-title {
  margin-top: 0;
  margin-bottom: 22px;
}
.roadmap-wrap-two .roadmap-item:nth-child(2),
.roadmap-wrap-two .roadmap-item:nth-child(1) {
  margin-left: 130px;
}
.roadmap-wrap-two .roadmap-item:nth-child(4) {
  width: 260px;
  flex: 0 0 260px;
}
.roadmap-wrap-two .roadmap-item {
  margin-bottom: 30px;
}
.roadmap-wrap-two .roadmap-item:nth-child(2) .roadmap-content::before,
.roadmap-wrap-two .roadmap-item:nth-child(2) .dot,
.roadmap-wrap-two .roadmap-item:nth-child(2) .dot::before {
  background: #ff9700;
}
.roadmap-wrap-two .roadmap-item:nth-child(3) .roadmap-content::before,
.roadmap-wrap-two .roadmap-item:nth-child(3) .dot,
.roadmap-wrap-two .roadmap-item:nth-child(3) .dot::before {
  background: #007ff4;
}
.roadmap-wrap-two .roadmap-item:nth-child(4) .roadmap-content::before,
.roadmap-wrap-two .roadmap-item:nth-child(4) .dot,
.roadmap-wrap-two .roadmap-item:nth-child(4) .dot::before {
  background: #ff4581;
}
.roadmap-wrap-two .roadmap-item:nth-child(2) .roadmap-title {
  color: #ff9700;
}
.roadmap-wrap-two .roadmap-item:nth-child(3) .roadmap-title {
  color: #007ff4;
}
.roadmap-wrap-two .roadmap-item:nth-child(4) .roadmap-title {
  color: #ff4581;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(1) .roadmap-content::before,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(1) .dot,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(1) .dot::before {
  background: #ff4581;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(2) .roadmap-content::before,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(2) .dot,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(2) .dot::before {
  background: #007ff4;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(3) .roadmap-content::before,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(3) .dot,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(3) .dot::before {
  background: #ff9700;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(4) .roadmap-content::before,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(4) .dot,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(4) .dot::before {
  background: #00c4f4;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(1) .roadmap-title {
  color: #ff4581;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(2) .roadmap-title {
  color: #007ff4;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(3) .roadmap-title {
  color: #ff9700;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(4) .roadmap-title {
  color: #00c4f4;
}

/* 12. Document */
.area-bg {
  // background-image: url(../img/bg/area_bg.png);
  background-size: cover;
  background-position: center;
}
.document-content .document-list {
  margin-bottom: 40px;
}
.document-content .document-list li {
  font-size: 18px;
  color: #fff;
  position: relative;
  margin-bottom: 15px;
  padding-left: 15px;
}
.document-content .document-list li:last-child {
  margin-bottom: 0;
}
.document-content .document-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
}

/* 13. Team */
.team-area {
  position: relative;
  z-index: 1;
}
.team-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, #0b1d33 0%, rgba(11, 29, 51, 0) 97.51%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: -1;
}
.team-item {
  text-align: center;
  margin-bottom: 60px;
}
.team-item .team-thumb {
  position: relative;
  border-radius: 50%;
  padding: 15px;
  margin-bottom: 30px;
  display: inline-block;
}
.team-item .team-thumb::before {
  content: "";
  left: 0;
  top: 0;
  width: 226px;
  height: 226px;
  background: transparent;
  border: 2px dashed #00c4f4;
  border-radius: 50%;
  position: absolute;
  transition: opacity 0.3s linear;
  animation: teamRotate 10s linear infinite;
  opacity: 0;
}
.team-item:hover .team-thumb::before {
  opacity: 1;
}
.team-item .team-thumb img {
  border-radius: 50%;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
}
.team-item:hover .team-thumb img {
  filter: grayscale(0);
}
.team-content .title {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  transition: 0.3s linear;
}
.team-content span {
  display: block;
  margin-bottom: 10px;
}
.team-content .team-social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -10px;
}
.team-content .team-social li {
  padding: 0 10px;
}
.team-content .team-social li a {
  color: #fff;
  font-size: 13px;
}
.team-content .team-social li a:hover {
  color: #00c4f4;
}

/* team-two */
.team-bg {
  // background-image: url(../img/bg/team_bg.jpg);
  background-size: cover;
  background-position: center;
  padding: 130px 0 100px;
  position: relative;
}
.team-bg::after,
.team-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  // background-image: url(../img/bg/team_shape01.png);
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 29px;
}
.team-bg::after {
  // background-image: url(../img/bg/team_shape02.png);
  top: auto;
  bottom: 0;
  height: 23px;
}
.team-item.team-item-two .team-thumb img {
  border-radius: 0;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
}
.team-item.team-item-two:hover .team-thumb img {
  filter: grayscale(0);
}
.team-item.team-item-two .team-thumb {
  border-radius: 0;
  padding: 0;
  margin-bottom: 35px;
}
.team-item.team-item-two .team-thumb::before {
  display: none;
}
.team-item.team-item-two {
  margin-bottom: 30px;
}

/* 14. Download */
.download-content p {
  color: #727885;
  margin-bottom: 30px;
}
.download-btn {
  display: flex;
  align-items: center;
}
.download-btn a {
  margin-right: 20px;
  margin-top: 10px;
  display: block;
}

/* 15. Faq */
.faq-area {
  background: #f3f6fc;
  padding: 120px 0 130px;
  position: relative;
  z-index: 1;
}
.faq-wrap .accordion-item {
  background-color: transparent;
  border: none;
  margin-bottom: 20px;
  position: relative;
}
.faq-wrap .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-wrap .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}
.faq-wrap .accordion-item:first-of-type .accordion-button:not(.collapsed) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.faq-wrap .accordion-button {
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: 1.25;
  padding: 37px 100px 37px 55px;
  border-radius: 100px;
  position: relative;
}
.faq-wrap .accordion-button:not(.collapsed) {
  color: #030b15;
  background-color: #fff;
  box-shadow: none;
  border-radius: 0;
}
.faq-wrap .accordion-body {
  padding: 0 40px 45px 55px;
  background: #fff;
  position: relative;
}
.faq-wrap .accordion-body::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: #00c4f4;
}
.faq-wrap .accordion-body p {
  margin-bottom: 0;
  color: #727885;
  font-size: 18px;
  line-height: 1.55;
}
.faq-wrap .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
}
.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}
.faq-wrap .accordion-button::after {
  width: auto;
  height: auto;
  content: "\f063";
  background-image: none;
  background-size: auto;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 25px;
  position: absolute;
  right: 50px;
  top: 36px;
  color: #132047;
  opacity: 0.3;
}
.faq-wrap .accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: rotate(-180deg);
  opacity: 1;
}
.faq-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.faq-shape-wrap img.img-one {
  left: 7%;
  bottom: 15%;
  animation: leftToRight 5s infinite linear;
}
.faq-shape-wrap img.img-two {
  right: 6%;
  top: 15%;
}
.faq-shape-wrap img.img-three {
  left: 50%;
  transform: translateX(-50%);
  bottom: 5%;
}

/* 16. Blog */
.blog-post-item {
  margin-bottom: 60px;
}
.blog-post-thumb {
  margin-bottom: 30px;
}
.blog-post-thumb img {
  border-radius: 15px;
  width: 100%;
}
.blog-meta ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.blog-meta ul li {
  color: #030b15;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  margin-right: 40px;
  margin-top: 5px;
}
.blog-meta ul li:last-child {
  margin-right: 0;
}
.blog-meta ul li i {
  margin-right: 10px;
  color: #564dca;
}
.blog-meta ul li a {
  color: #030b15;
}
.blog-meta ul li a:hover {
  color: #564dca;
}
.blog-post-content .title {
  color: #030b15;
  letter-spacing: -0.02em;
  font-size: 36px;
  margin-bottom: 20px;
  line-height: 1.27;
}
.blog-post-content .title a:hover {
  color: #564dca;
}
.blog-post-content p {
  margin-bottom: 30px;
}
.blog-post-content .btn.btn-two i {
  margin-left: 20px;
}
.blog-sidebar {
  margin-left: 25px;
}
.blog-widget {
  border: 1px solid #f2f5fa;
  box-shadow: 0px 12px 10px rgba(238, 239, 240, 0.25);
  background: #fff;
  padding: 40px 30px;
  margin-bottom: 40px;
}
.blog-widget .bw-title {
  font-size: 22px;
  margin-bottom: 20px;
  color: #030b15;
  letter-spacing: -0.02em;
}
.blog-widget .sidebar-search {
  position: relative;
}
.blog-widget .sidebar-search input {
  background: #f3f6fc;
  border: 1px solid #f2f4f6;
  color: #030a39;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 19px 50px 19px 20px;
  height: 60px;
}
.blog-widget .sidebar-search input::placeholder {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #727885;
}
.blog-widget .sidebar-search button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  border: none;
  background: transparent;
  padding: 0;
  font-size: 14px;
  color: #030a39;
}
.blog-widget .category-list ul li {
  margin-bottom: 15px;
}
.blog-widget .category-list ul li:last-child {
  margin-bottom: 0;
}
.blog-widget .category-list ul li a {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #727885;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  border: 1px solid #f2f5fa;
  background: #ffffff;
  padding: 11px 18px;
}
.blog-widget .category-list ul li a span {
  margin-left: auto;
}
.blog-widget .category-list ul li a:hover {
  color: #171717;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.04);
}
.blog-widget .page-list ul li {
  margin-bottom: 20px;
}
.blog-widget .page-list ul li:last-child {
  margin-bottom: 0;
}
.blog-widget .page-list ul li a {
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #727885;
}
.blog-widget .page-list ul li a i {
  margin-left: auto;
}
.blog-widget .page-list ul li a:hover {
  color: #171717;
}
.rc-post-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.rc-post-item:last-child {
  margin-bottom: 0;
}
.rc-post-thumb {
  width: 97px;
  flex: 0 0 97px;
  margin-right: 25px;
}
.rc-post-thumb img {
  border-radius: 10px;
}
.rc-post-content span {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #727885;
  display: block;
  margin-bottom: 5px;
}
.rc-post-content .title {
  font-size: 15px;
  color: #141515;
  margin-bottom: 0;
  line-height: 1.42;
}
.rc-post-content .title a:hover {
  color: #564dca;
}
.blog-widget .tag-list ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -5px -10px;
}
.blog-widget .tag-list ul li {
  padding: 0 5px 10px;
}
.blog-widget .tag-list ul li a {
  color: #b6b7ba;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid #f2f4f6;
  padding: 6px 20px;
  display: block;
}
.blog-widget .tag-list ul li a:hover {
  opacity: 1;
  color: #fff;
  background: #564dca;
  border-color: #564dca;
}
.blog-widget:last-child {
  margin: 0 0;
}

/* blog-details */
.blog-details-wrap .blog-post-content p {
  margin-bottom: 15px;
}
.bd-approach-wrap {
  margin: 50px 0 65px;
}
.bd-approach-wrap .row .col-46 {
  width: 46%;
  flex: 0 0 46%;
}
.bd-approach-wrap .row .col-54 {
  width: 54%;
  flex: 0 0 54%;
}
.bd-approach-content {
  width: 93%;
}
.bd-approach-content .title {
  font-size: 24px;
  margin-bottom: 20px;
}
.bd-approach-content ul li {
  font-weight: 500;
  font-size: 15px;
  color: #171151;
  margin-bottom: 5px;
}
.bd-approach-content ul li:last-child {
  margin-bottom: 0;
}
.bd-approach-content ul li i {
  color: #564dca;
  margin-right: 15px;
}
.bd-approach-img img {
  border-radius: 10px;
}
.bd-technology-content .title {
  letter-spacing: -0.03em;
  font-size: 24px;
  margin-bottom: 25px;
}
.blog-details-img {
  margin: 35px 0 40px;
}
.blog-details-img img {
  border-radius: 10px;
  margin-bottom: 20px;
}
.blog-details-bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 36px 0;
  border-top: 1px solid #eceef3;
  border-bottom: 1px solid #eceef3;
}
.blog-details-tags {
  width: 70%;
  flex: 0 0 70%;
}
.blog-details-tags ul {
  display: flex;
  align-items: center;
}
.blog-details-tags ul li {
  margin-top: 5px;
  margin-bottom: 5px;
}
.blog-details-social ul li.social-title,
.blog-details-tags ul li.tag-title {
  font-weight: 600;
  font-size: 20px;
  font-family: "Poppins";
  letter-spacing: -0.02em;
  color: #030b15;
  margin-right: 20px;
}
.blog-details-tags ul li a {
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #c1c1c1;
  display: block;
  padding: 5px 18px;
}
.blog-details-tags ul li a:hover {
  background: #564dca;
  color: #fff;
}
.blog-details-social {
  width: 30%;
  flex: 0 0 30%;
}
.blog-details-social ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.blog-details-social ul li {
  margin-right: 20px;
}
.blog-details-social ul li:last-child {
  margin-right: 0;
}
.blog-details-social ul li a {
  font-size: 15px;
  color: #0072ac;
}
.blog-details-social ul li:nth-child(3) a {
  color: #da0021;
}
.blog-details-social ul li:nth-child(4) a {
  color: #0072ac;
}
.blog-details-social ul li:nth-child(5) a {
  color: #3ac4ff;
}
.comment-wrap {
  border-bottom: 1px solid #eceef3;
}
.comment-wrap .title {
  font-size: 20px;
  color: #030b15;
  margin: 0 0 30px;
}
.latest-comments ul li .comments-box {
  display: flex;
  align-items: flex-start;
  width: 74%;
  margin-bottom: 60px;
}
.latest-comments ul li .comments-box .comments-avatar {
  width: 80px;
  flex: 0 0 80px;
  margin-right: 30px;
}
.latest-comments ul li .comments-box .comments-avatar img {
  border-radius: 50%;
}
.latest-comments ul li .comments-box .avatar-name {
  margin-bottom: 10px;
}
.latest-comments ul li .comments-box .avatar-name .title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: #030b15;
  font-size: 16px;
  margin-bottom: 0;
}
.latest-comments ul li .comments-box .avatar-name .title a {
  font-size: 20px;
  opacity: 0.5;
}
.latest-comments ul li .comments-box .avatar-name .title a:hover {
  opacity: 1;
}
.latest-comments ul li .comments-box .avatar-name span {
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  color: #727885;
}
.latest-comments ul li .comments-box .comment-text p {
  margin-bottom: 0;
  font-size: 14px;
}
.latest-comments ul li .children {
  margin-left: 100px;
}
.latest-comments ul li .children .comments-box {
  width: 84%;
}
.post-comments-form {
  margin-top: 50px;
}
.post-comment-content {
  margin-bottom: 45px;
}
.post-comment-content .title {
  color: #030b15;
  margin-bottom: 10px;
  letter-spacing: -0.02em;
  font-size: 20px;
}
.post-comment-content p {
  margin-bottom: 0;
}
.comment-form .form-grp {
  margin-bottom: 30px;
}
.comment-form .form-grp textarea,
.comment-form .form-grp input {
  display: block;
  border: none;
  width: 100%;
  background: #f3f6fc;
  border-radius: 5px;
  color: #171151;
  font-weight: 400;
  font-size: 15px;
  display: block;
  padding: 17px 20px;
  height: 60px;
}
.comment-form .form-grp textarea::placeholder,
.comment-form .form-grp input::placeholder {
  color: #b2b0c1;
  font-weight: 400;
  font-size: 15px;
}
.comment-form .form-grp textarea {
  min-height: 172px;
  max-height: 172px;
}
.comment-form .btn.btn-two:hover {
  background: #00c4f4;
}

/* 17. Pagination */
.pagination-wrap ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 0 !important;
}
.pagination-wrap ul li {
  display: block;
  margin: 10px 3.7px 0;
}
.pagination-wrap ul li a {
  width: 49px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f2f5fa;
  border-radius: 5px;
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  color: #8a879f;
}
.pagination-wrap ul li a:hover,
.pagination-wrap ul li .current {
  color: #fff;
  background: #564dca;
  border-color: #564dca;
}

/* 18. Contact */
.contact-info-wrap {
  margin-bottom: 50px;
}
.contact-info-item {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}
.contact-info-item::before {
  content: "";
  position: absolute;
  right: -61px;
  top: 44px;
  // background-image: url(../img/images/line.png);
  width: 122px;
  height: 9px;
}
.contact-info-wrap .row [class*="col-"]:last-child .contact-info-item::before {
  display: none;
}
.contact-info-item .icon {
  width: 107px;
  height: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  font-size: 28px;
  color: #ff9700;
  margin: 0 auto;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.07);
  z-index: 1;
  margin-bottom: 10px;
}
.contact-info-item .icon-background {
  position: absolute;
  width: 83px;
  height: 83px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #0b1d33;
  border-radius: 50%;
  z-index: -1;
}
.contact-info-item .icon-background::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 83px;
  height: 83px;
  background: transparent;
  border: 3px solid #00c4f4;
}
.contact-info-item .content p {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 30px;
}
.contact-info-wrap .row [class*="col-"]:nth-child(2) .icon {
  color: #ff4581;
}
.contact-info-wrap .row [class*="col-"]:nth-child(3) .icon {
  color: #00c4f4;
}
.contact-info-wrap .row [class*="col-"]:nth-child(2) .icon-background::before {
  border-color: #ff4581;
}
.contact-info-wrap .row [class*="col-"]:nth-child(3) .icon-background::before {
  border-color: #00c4f4;
}
.contact-form-wrap {
  background-image: url(../images/contact_bg.png);
  background-position: center;
  background-size: cover;
  padding: 80px;
  border-radius: 30px;
}
.contact-form-wrap .form-grp {
  margin-bottom: 15px;
}
.contact-form-wrap .form-grp textarea,
.contact-form-wrap .form-grp input {
  width: 100%;
  background: #0b1d33;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  padding: 18px 20px;
  height: 65px;
}
.contact-form-wrap .form-grp textarea::placeholder,
.contact-form-wrap .form-grp input::placeholder {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  opacity: 0.7;
}
.contact-form-wrap .form-grp textarea {
  min-height: 184px;
  max-height: 184px;
}
.contact-form-wrap .submit-btn .btn {
  background: #0b1d33;
  border-color: #00c4f4;
}

/* contact-two */
.contact-bg {
  // background-image: url(../img/bg/contact_bg02.jpg);
  background-size: cover;
  background-position: center;
  padding: 130px 0 250px;
  position: relative;
}
.contact-bg::after,
.contact-bg::before {
  /* content: ""; */
  position: absolute;
  left: 0;
  top: 0;
  // background-image: url(../img/bg/contact_shape01.png);
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 29px;
}
.contact-bg::after {
  // background-image: url(../img/bg/contact_shape02.png);
  top: auto;
  bottom: 0;
  height: 18px;
}
.contact-inner {
  padding: 0 40px;
}
.contact-info-wrap-two .title {
  font-size: 50px;
  margin-bottom: 35px;
  letter-spacing: -0.03em;
  line-height: 1.2;
}
.contact-list-item {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  display: flex;
  align-items: center;
  padding: 22px 32px;
  width: 69%;
  margin-bottom: 15px;
}
.contact-list-item:last-child {
  margin-bottom: 0;
}
.contact-list-item .icon {
  width: 35px;
  /* flex: 0 0 35px; */
  margin-right: 22px;
}
.contact-list-item .content p {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 146.49%;
  color: #e3e3e3;
}
.contact-form-wrap-two .title {
  font-size: 30px;
  text-transform: none;
  letter-spacing: -0.03em;
  line-height: 2;
  margin-bottom: 35px;
}
.contact-form-wrap-two textarea,
.contact-form-wrap-two input {
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  width: 100%;
  letter-spacing: -0.02em;
  color: #030b15;
  font-weight: 400;
  font-size: 20px;
  padding: 17px 22px;
  line-height: 1.5;
  height: 66px;
  margin-bottom: 20px;
  display: block;
  transition: 0.3s linear;
}
.contact-form-wrap-two textarea::placeholder,
.contact-form-wrap-two input::placeholder {
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.38);
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
}
.contact-form-wrap-two textarea:focus,
.contact-form-wrap-two input:focus {
  background: #fff;
  border-color: #fff;
}
.contact-form-wrap-two textarea {
  min-height: 165px;
  max-height: 165px;
}
.contact-form-wrap-two .btn.btn-two {
  background: #00c4f4;
}
.contact-form-wrap-two .btn.btn-two:hover {
  background: #564dca;
}

/* 19. Newsletter */
.newsletter-wrap {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 26px 23px rgba(81, 98, 132, 0.06);
  border-radius: 10px;
  padding: 64px 50px;
  margin-top: -120px;
  z-index: 1;
  position: relative;
}
.newsletter-content {
  width: 42.5%;
  flex: 0 0 42.5%;
}
.newsletter-content .title {
  color: #030b15;
  font-size: 32px;
  line-height: 1.5;
  margin-bottom: 0;
  letter-spacing: -1px;
}
.newsletter-form {
  width: 57.5%;
  flex: 0 0 57.5%;
}
.newsletter-form form {
  position: relative;
}
.newsletter-form input {
  width: 100%;
  border: 1px solid #eceef3;
  border-radius: 4px;
  background: #f3f6fc;
  font-size: 16px;
  font-weight: 400;
  color: #030b15;
  padding: 26px 215px 26px 25px;
  line-height: 1.25;
  height: 72px;
}
.newsletter-form input::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #667279;
}
.newsletter-form form button {
  position: absolute;
  right: 12px;
  top: 8px;
  bottom: 8px;
}
.newsletter-form .btn.btn-two {
  padding: 21px 47px;
}

/* 20. Footer */
.footer-area {
  position: relative;
  z-index: 1;
}
.footer-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    359.43deg,
    #0b1d33 11.06%,
    rgba(11, 29, 51, 0) 99.43%
  );
  z-index: -1;
}
.footer-scroll-wrap {
  position: relative;
  z-index: 1;
}
.footer-scroll-wrap .scroll-to-target {
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #030b15;
  border: 1px solid #1f262f;
  border-radius: 50%;
  font-size: 18px;
  padding: 0 0;
  color: #fff;
  margin: 0 auto;
}
.footer-scroll-wrap .scroll-to-target:hover {
  background: #00c4f4;
  border-color: #00c4f4;
}
.footer-scroll-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background: #1f262f;
  z-index: -1;
}
.footer-top {
  padding: 55px 0 50px;
}
.footer-widget {
  margin-bottom: 30px;
}
.footer-widget .f-logo {
  margin-bottom: 20px;
  display: block;
}
.footer-content p {
  margin-bottom: 25px;
  font-size: 15px;
  line-height: 26px;
  color: #a4b4c3;
}
.footer-content .footer-social {
  display: flex;
  align-items: center;
  margin: 0 -7px;
}
.footer-content .footer-social li {
  padding: 0 7px;
}
.footer-content .footer-social li a {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #719ed6;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
}
.footer-content .footer-social li a:hover {
  background: #00c4f4;
  border-color: #00c4f4;
}
.footer-widget .fw-title {
  font-size: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 25px 0 27px;
}
.footer-link ul li {
  margin-bottom: 10px;
}
.footer-link ul li:last-child {
  margin-bottom: 0;
}
.footer-link ul li a {
  font-size: 15px;
  line-height: 20px;
  color: #a4b4c3;
  position: relative;
}
.footer-link ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.footer-link ul li a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.footer-link ul li a:hover {
  color: #fff;
}
.footer-top .row [class*="col-"]:nth-child(2) .footer-widget {
  margin-left: 80px;
}
.footer-top .row [class*="col-"]:nth-child(4) .footer-widget {
  margin-left: 75px;
}
.footer-newsletter p {
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 60px;
  color: #a4b4c3;
}
.footer-newsletter form {
  position: relative;
}
.footer-newsletter form input {
  width: 100%;
  border: none;
  border-radius: 10px;
  font-weight: 400;
  font-size: 15px;
  padding: 23px 80px 23px 25px;
  color: #fff;
  height: 72px;
}
.footer-newsletter form input::placeholder {
  font-weight: 400;
  font-size: 15px;
  color: #fff;
}
.footer-newsletter form button {
  position: absolute;
  right: 0;
  top: 0;
  width: 65px;
  height: 72px;
  background: #00c4f4;
  border-radius: 10px;
  font-size: 20px;
  color: #fff;
  border: none;
}
.footer-bottom {
  border-top: 1px solid;
  padding: 27px 0;
}
.copyright-text p {
  color: #fff;
  margin-bottom: 0;
  font-size: 15px;
}
.footer-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -30px;
}
.footer-menu ul li {
  padding: 0 30px;
}
.footer-menu ul li a {
  font-size: 15px;
  color: #a4b4c3;
  font-weight: 500;
}
.footer-menu ul li a:hover {
  color: #fff;
}

/* footer-two */
.footer-area-two .footer-top {
  padding: 70px 40px 65px;
}
.footer-menu-two .navigation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.footer-menu-two .navigation li {
  margin-right: 60px;
}
.footer-menu-two .navigation li:last-child {
  margin-right: 0;
}
.footer-menu-two .navigation li a {
  color: #030b15;
  font-weight: 500;
  font-size: 16px;
}
.footer-menu-two .navigation li a:hover {
  color: #00c4f4;
}
.footer-area-two .footer-social ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-area-two .footer-social ul li {
  margin-right: 40px;
}
.footer-area-two .footer-social ul li:last-child {
  margin-right: 0;
}
.footer-area-two .footer-social ul li a {
  color: #0a142f;
  opacity: 0.3;
  font-size: 24px;
  line-height: 1;
}
.footer-area-two .footer-social ul li a:hover {
  color: #00c4f4;
  opacity: 1;
}
.footer-area-two .footer-bottom {
  border-top: 1px solid #f0f3f9;
  padding: 40px 40px;
}
.footer-area-two .copyright-text p {
  color: #282f3b;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}
.footer-area-two .scroll-up .scroll-to-target {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #564dca;
  padding: 0 0;
  border: none;
  color: #fff;
  font-size: 17px;
  margin: -60px auto 15px;
}
.footer-area-two .scroll-up .scroll-to-target:hover {
  background: #00c4f4;
}
.footer-area-two .scroll-up span {
  display: block;
  color: #0a142f;
  font-size: 14px;
  font-weight: 500;
}
.footer-bottom-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-bottom-menu ul li {
  margin-right: 30px;
}
.footer-bottom-menu ul li:last-child {
  margin-right: 0;
}
.footer-bottom-menu ul li a {
  font-size: 15px;
  color: #0a142f;
  font-weight: 500;
}
.footer-bottom-menu ul li a:hover {
  color: #00c4f4;
}

/* footer-three */
.footer-bg {
  // background-image: url(../img/bg/breadcrumb-bg.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 120px;
}
.footer-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  // background-image: url(../img/bg/footer_shape.png);
  background-repeat: repeat;
  background-position: center;
  width: 100%;
  height: 29px;
}
.footer-area-three .footer-top {
  padding: 0 0 50px;
}

.bt-roadmap-wrap {
  display: flex;
  padding: 0;
  min-height: 490px;
  margin: 10px 0 0;
  justify-content: flex-end;
}
.bt-roadmap_x {
  position: relative;
}
.bt-roadmap_x::before {
  content: "";
  position: absolute;
  left: 0;
  top: calc(50% - 0px);
  transform: translateY(-50%);
  width: 100%;
  height: 8px;
  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  opacity: 0.1;
}
.bt-roadmap_x-2::before {
  width: 1050px !important;
}
.bt-roadmap-item {
  display: flex;
  width: 370px;
  flex: 0 0 auto;
  height: 305px;
  align-self: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.bt-roadmap-item-xs {
  width: 320px;
}
// .bt-roadmap-item:nth-child(even) {
//   align-self: flex-start;
//   flex-direction: column-reverse;
//   margin-top: -10px;
// }
// .bt-roadmap-item:nth-child(even) .roadmap-title {
//   margin: 22px 0 10px;
// }
.roadmap-content span {
  display: block;
  color: #a4b4c3;
}
// .bt-roadmap-item:nth-child(even) .roadmap-content {
//   padding: 0 0 110px 17px;
// }
// .bt-roadmap-item:nth-child(even) .roadmap-content .dot {
//   top: auto;
//   bottom: 0;
// }
.bt-roadmap-item:not(:first-child) {
  margin-left: -100px;
}
.bt-roadmap-item:last-child {
  width: 280px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  display: none;
}
.bt-roadmap_x._mCS_1.mCS_no_scrollbar {
  padding-bottom: 0;
}
.bt-roadmap_x.mCustomScrollbar {
  padding: 0 0 30px;
}
.mCustomScrollBox
  + .mCSB_scrollTools
  + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
}
.bt-roadmap_x:not(.mCS_no_scrollbar):before {
  top: calc(50% - 15px);
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #00c2f2;
}

/* 21. Preloader */
#preloader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #030b15;
  z-index: 999;
  width: 100%;
  height: 100%;
}
.spinner {
  margin: 300px auto;
  width: 80px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}
.spinner > div {
  background-color: #00c2f2;
  height: 100%;
  width: 6px;
  margin-right: 2px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

/* MyStyles */
/* MyStyles */
/* MyStyles */
/* MyStyles */

.price_plan_area {
  position: relative;
  z-index: 1;
  background-color: #f5f5ff;
}

.single_price_plan {
  position: relative;
  z-index: 1;
  border-radius: 0 0 0 0;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  /* margin-bottom: 10px; */
  /* background-color: #ffffff; */
  padding: 3rem 4rem;
}
// media
// media
// media

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-control,
  select,
  .email-compose-fields
    .select2-container--default
    .select2-selection--multiple,
  .select2-container--default .select2-selection--single,
  .select2-container--default .select2-selection--single .select2-search__field,
  .typeahead,
  .tt-query,
  .tt-hint {
    transition: none;
  }
}
.form-control::-ms-expand,
select::-ms-expand,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple::-ms-expand,
.select2-container--default .select2-selection--single::-ms-expand,
.select2-container--default
  .select2-selection--single
  .select2-search__field::-ms-expand,
.typeahead::-ms-expand,
.tt-query::-ms-expand,
.tt-hint::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring,
select:-moz-focusring,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple:-moz-focusring,
.select2-container--default .select2-selection--single:-moz-focusring,
.select2-container--default
  .select2-selection--single
  .select2-search__field:-moz-focusring,
.typeahead:-moz-focusring,
.tt-query:-moz-focusring,
.tt-hint:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus,
select:focus,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple:focus,
.select2-container--default .select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.typeahead:focus,
.tt-query:focus,
.tt-hint:focus {
  color: #eef4ff;
  background-color: #04060c;
  border-color: #727cf5;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder,
select::placeholder,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple::placeholder,
.select2-container--default .select2-selection--single::placeholder,
.select2-container--default
  .select2-selection--single
  .select2-search__field::placeholder,
.typeahead::placeholder,
.tt-query::placeholder,
.tt-hint::placeholder {
  color: #7886a1;
  opacity: 1;
}
.form-control:disabled,
select:disabled,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple:disabled,
.select2-container--default .select2-selection--single:disabled,
.select2-container--default
  .select2-selection--single
  .select2-search__field:disabled,
.typeahead:disabled,
.tt-query:disabled,
.tt-hint:disabled,
.form-control[readonly],
select[readonly],
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple[readonly],
.select2-container--default .select2-selection--single[readonly],
.select2-container--default
  .select2-selection--single
  .select2-search__field[readonly],
.typeahead[readonly],
.tt-query[readonly],
.tt-hint[readonly] {
  background-color: #18284e;
  opacity: 1;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
@media (max-width: 991px) {
  .main-wrapper .page-wrapper {
    margin-left: 0;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .main-wrapper .page-wrapper .page-content {
    padding: 25px 15px;
  }
}
@media (min-width: 1200px) {
  .page-content .content-nav-wrapper {
    display: block;
  }
}
@media (max-width: 991px) {
  .navbar {
    width: 100%;
    left: 0;
  }
  .navbar .navbar-content {
    width: calc(100% - 70px - 1px);
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_price_plan {
    padding: 3rem;
  }
}
@media only screen and (max-width: 575px) {
  .single_price_plan {
    padding: 3rem;
  }
}
.single_price_plan::after {
  position: absolute;
  content: "";
  background-repeat: repeat;
  width: 100%;
  height: 17px;
  bottom: -17px;
  z-index: 1;
  left: 0;
}
.single_price_plan .title {
  text-transform: capitalize;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  margin-bottom: 2rem;
}
.single_price_plan .title span {
  color: #ffffff;
  padding: 0.2rem 0.6rem;
  font-size: 12px;
  text-transform: uppercase;
  background-color: #2ecc71;
  display: inline-block;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}
.single_price_plan .title h3 {
  font-size: 1.25rem;
}
.single_price_plan .title p {
  font-weight: 300;
  line-height: 1;
  font-size: 14px;
  text-transform: lowercase;
}
.single_price_plan .title .line {
  width: 80px;
  height: 4px;
  border-radius: 10px;
  background-color: #3f43fd;
}
.single_price_plan .price {
  margin-bottom: 1.5rem;
}
.single_price_plan .price h6 {
  position: relative;
  z-index: 1;
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 0;
  color: #3f43fd;
  display: inline-block;
  // -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#2d2ed4)
  );
  background-image: linear-gradient(90deg, #fff, #2d2ed4);
}
.single_price_plan .price h4 {
  position: relative;
  z-index: 1;
  font-size: 2.4rem;
  line-height: 1;
  margin-bottom: 0;
  color: #3f43fd;
  display: inline-block;
  // -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#2d2ed4)
  );
  background-image: linear-gradient(90deg, #fff, #2d2ed4);
}
.icon .price h4 {
  position: relative;
  z-index: 1;
  font-size: 2.4rem;
  line-height: 1;
  margin-bottom: 0;
  color: #3f43fd;
  display: inline-block;
  // -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#00c4f4),
    to(#2d2ed4)
  );
  background-image: linear-gradient(90deg, #00c4f4, #2d2ed4);
}
.single_price_plan .description {
  position: relative;
  margin-bottom: 1.5rem;
}
.single_price_plan .description p {
  line-height: 30px;
  margin: 0;
  padding: 3px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}
.single_price_plan .description p i {
  color: #2ecc71;
  margin-right: 0.5rem;
}
.single_price_plan .description p .lni-close {
  color: #e74c3c;
}
.single_price_plan.active,
.single_price_plan:hover,
.single_price_plan:focus {
  -webkit-box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
  box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
}
.single_price_plan .side-shape img {
  position: absolute;
  width: auto;
  top: 0;
  right: 0;
  z-index: -2;
}

.section-heading h3 {
  margin-bottom: 1rem;
  font-size: 3.125rem;
  letter-spacing: -1px;
}

.section-heading p {
  margin-bottom: 0;
  font-size: 1.25rem;
}

.section-heading .line {
  width: 120px;
  height: 5px;
  margin: 30px auto 0;
  border-radius: 6px;
  background: #2d2ed4;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#e24997),
    to(#2d2ed4)
  );
  background: linear-gradient(to right, #e24997, #2d2ed4);
}
.my-contemt h2 {
  position: relative;
  opacity: 0.7;
  z-index: 1;
  font-size: 2.4rem;
  line-height: 1;
  margin-bottom: 0;
  color: #3f43fd;
  display: inline-block;
  // -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#00c4f4)
  );
  background-image: linear-gradient(90deg, #fff, #00c4f4);
}
.my-contemt .contact-list-item {
  position: relative;
  opacity: 0.7;
  z-index: 1;
  font-size: 2.4rem;
  line-height: 1;
  margin-bottom: 0;
  color: #3f43fd;
  display: inline-block;
  // -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#564dca)
  );
  background-image: linear-gradient(90deg, #fff, #564dca);
}
.label-login {
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
}
.login-container {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
// sidebar sidebar sidebar sidebar

.infinite-spin,
.settings-sidebar .sidebar-body .settings-sidebar-toggler svg {
  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.navbar .sidebar-toggler {
  height: 100%;
  border-right: 1px solid #e8ebf1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 25px;
  display: none;
  background-color: #212529;
}
.navbar .sidebar-toggler svg {
  width: 20px;
  height: 20px;
  color: #9b9b9b;
}
@media (max-width: 991px) {
  .navbar .sidebar-toggler {
    display: flex;
  }
}
.sidebar {
  width: 240px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  -webkit-transition: width 0.1s ease, margin 0.1s ease-out;
  transition: width 0.1s ease, margin 0.1s ease-out;
  z-index: 999;
}
.sidebar .sidebar-header {
  background: #ffffff;
  height: 60px;
  border-bottom: 1px solid #f2f4f9;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 25px;
  border-right: 1px solid #f2f4f9;
  z-index: 999;
  width: 100%;
  -webkit-transition: width 0.1s ease;
  transition: width 0.1s ease;
}
.sidebar-open .sidebar .sidebar-header {
  border-bottom: 1px solid #e8ebf1;
}
.sidebar .sidebar-header .sidebar-brand {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  font-weight: 900;
  font-size: 25px;
  letter-spacing: -1px;
  color: #031a61;
}
.sidebar .sidebar-header .sidebar-brand span {
  color: #727cf5;
  font-weight: 300;
}
.sidebar .sidebar-header .sidebar-toggler {
  cursor: pointer;
  width: 28px;
}
.sidebar .sidebar-header .sidebar-toggler span {
  display: block;
  width: 100%;
  border-radius: 3px;
  height: 2px;
  background: #535353;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}
.sidebar .sidebar-header .sidebar-toggler span + span {
  margin-top: 4px;
}
.sidebar .sidebar-header .sidebar-toggler.active span:nth-child(1) {
  -webkit-animation: ease 0.6s top forwards;
  animation: ease 0.6s top forwards;
}
.sidebar .sidebar-header .sidebar-toggler.not-active span:nth-child(1) {
  -webkit-animation: ease 0.6s top-2 forwards;
  animation: ease 0.6s top-2 forwards;
}
.sidebar .sidebar-header .sidebar-toggler.active span:nth-child(2) {
  -webkit-animation: ease 0.6s scaled forwards;
  animation: ease 0.6s scaled forwards;
}
.sidebar .sidebar-header .sidebar-toggler.not-active span:nth-child(2) {
  -webkit-animation: ease 0.6s scaled-2 forwards;
  animation: ease 0.6s scaled-2 forwards;
}
.sidebar .sidebar-header .sidebar-toggler.active span:nth-child(3) {
  -webkit-animation: ease 0.6s bottom forwards;
  animation: ease 0.6s bottom forwards;
}
.sidebar .sidebar-header .sidebar-toggler.not-active span:nth-child(3) {
  -webkit-animation: ease 0.6s bottom-2 forwards;
  animation: ease 0.6s bottom-2 forwards;
}
.sidebar .sidebar-body {
  max-height: calc(100% - 60px);
  position: relative;
  border-right: 1px solid #f2f4f9;
  height: 100%;
  // -webkit-box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.2);
  // box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.2);
  background: #ffffff;
}
.sidebar .sidebar-body .nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding: 25px 25px 50px 25px;
}
.sidebar .sidebar-body .nav .nav-item {
  position: relative;
  margin: 3px 0px;
  padding: 4px 10px;
}
.sidebar .sidebar-body .nav .nav-item .nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  height: 32px;
  white-space: nowrap;
  color: #000;
}
.sidebar .sidebar-body .nav .nav-item .nav-link .link-icon {
  width: 16px;
  height: 16px;
  fill: rgba(233, 236, 239, 0.21);
  position: absolute;
  color: inherit;
}
.sidebar .sidebar-body .nav .nav-item .nav-link .link-title {
  margin-left: 25px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.sidebar .sidebar-body .nav .nav-item .nav-link .link-arrow {
  width: 14px;
  height: 14px;
  margin-left: auto;
  -webkit-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: inherit;
}
.sidebar .sidebar-body .nav .nav-item .nav-link .link-icon,
.sidebar .sidebar-body .nav .nav-item .nav-link .link-title,
.sidebar .sidebar-body .nav .nav-item .nav-link .link-arrow {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar .sidebar-body .nav .nav-item .nav-link[aria-expanded="true"] {
  color: #727cf5;
}
.sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link[aria-expanded="true"]
  .link-arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(180deg);
}
.sidebar .sidebar-body .nav .nav-item.nav-category {
  color: #686868;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  height: 15px;
}
.sidebar .sidebar-body .nav .nav-item.nav-category:not(:first-child) {
  margin-top: 20px;
}
.sidebar .sidebar-body .nav .nav-item:hover .nav-link {
  color: #727cf5;
}
.sidebar .sidebar-body .nav .nav-item:hover {
  border-radius: 8px;
  // background-color: rgb(240, 240, 240);
}
.sidebar .sidebar-body .nav .nav-item:hover .nav-link .link-title {
  margin-left: 31px;
}
.sidebar .sidebar-body .nav .nav-item:hover .nav-link .link-icon {
  color: #727cf5;
  fill: rgba(239, 243, 255, 0.5);
}
.sidebar .sidebar-body .nav .nav-item.active .nav-link {
  color: #727cf5;
}
.sidebar .sidebar-body .nav .nav-item.active .nav-link::before {
  content: "";
  width: 3px;
  height: 26px;
  background: #727cf5;
  position: absolute;
  left: -25px;
}
.sidebar .sidebar-body .nav .nav-item.active .nav-link .link-icon {
  // fill: rgba(239, 243, 255, 0.5);
  color: #727cf5;
}
.sidebar .sidebar-body .nav.sub-menu {
  padding: 0 0 15px 33px;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item {
  position: relative;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link {
  height: 25px;
  color: #000;
  font-size: 13px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #a9b4ca;
  position: absolute;
  left: -29px;
  top: 10px;
  -webkit-transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link.active {
  color: #727cf5;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link.active::before {
  border: 1px solid #727cf5;
  background: #727cf5;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item:hover .nav-link {
  color: #727cf5;
  margin-left: 3px;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item:hover .nav-link::before {
  border: 1px solid #727cf5;
  background: #727cf5;
}
@media (max-width: 991px) {
  .sidebar {
    z-index: 999;
    margin-left: -240px;
    visibility: hidden;
  }
  .sidebar-open .sidebar {
    margin-left: 0;
    visibility: visible;
  }
  .sidebar .sidebar-body .nav .nav-item {
    width: auto;
  }
  .sidebar .sidebar-body .nav .nav-item .nav-link .link-icon {
    -webkit-transition: none;
    transition: none;
    margin: 0;
  }
}

.sidebar-dark .sidebar .sidebar-header {
  background: #0c1427;
  border-bottom: 1px solid #344258;
  border-right: 1px solid #344258;
}
.sidebar-dark .sidebar .sidebar-header .sidebar-brand {
  color: #f8f9fc;
}
.sidebar-dark .sidebar .sidebar-header .sidebar-toggler span {
  background: #9fa2ab;
}

.sidebar-dark .sidebar .sidebar-body {
  background: #0c1427;
  border-right: 1px solid #344258;
}
.sidebar-dark .sidebar .sidebar-body .nav .nav-item.nav-category {
  color: #ffffff;
}
.sidebar-dark .sidebar .sidebar-body .nav .nav-item .nav-link {
  color: #bfc3ce;
}
.sidebar-dark .sidebar .sidebar-body .nav .nav-item .nav-link svg {
  fill: none;
}
.sidebar-dark
  .sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link[aria-expanded="true"] {
  color: #727cf5;
}
.sidebar-dark .sidebar .sidebar-body .nav .nav-item:hover .nav-link svg,
.sidebar-dark .sidebar .sidebar-body .nav .nav-item.active .nav-link svg {
  fill: rgba(114, 124, 245, 0.2);
}
.sidebar-dark .sidebar .sidebar-body .nav .nav-item:hover .nav-link .link-title,
.sidebar-dark
  .sidebar
  .sidebar-body
  .nav
  .nav-item.active
  .nav-link
  .link-title {
  color: #727cf5;
}

.settings-sidebar {
  position: fixed;
  right: -232px;
  top: 130px;
  width: 232px;
  background: #ffffff;
  -webkit-box-shadow: -3px 0 10px 0 rgba(183, 192, 206, 0.2);
  box-shadow: -3px 0 10px 0 rgba(183, 192, 206, 0.2);
  z-index: 999;
  border-radius: 0 0 0 4px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.settings-open .settings-sidebar {
  right: 0;
}
.settings-sidebar .sidebar-body {
  position: relative;
  padding: 18px;
}
.settings-sidebar .sidebar-body .settings-sidebar-toggler {
  position: absolute;
  left: -44px;
  top: 0;
  padding: 12px;
  border-radius: 4px 0 0 4px;
  background: #ffffff;
  -webkit-box-shadow: -3px 0 10px 0 rgba(183, 192, 206, 0.2);
  box-shadow: -3px 0 10px 0 rgba(183, 192, 206, 0.2);
}
.settings-sidebar .sidebar-body .settings-sidebar-toggler svg {
  width: 20px;
  height: 20px;
  color: #686868;
}
.settings-sidebar .sidebar-body .theme-wrapper .theme-item {
  position: relative;
  display: block;
  margin-bottom: 19px;
  border-radius: 6px;
  border: 3px solid #ececec;
}
.settings-sidebar .sidebar-body .theme-wrapper .theme-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(114, 124, 245, 0);
}
.settings-sidebar .sidebar-body .theme-wrapper .theme-item:last-child {
  margin-bottom: 0;
}
.settings-sidebar .sidebar-body .theme-wrapper .theme-item.active {
  border: 3px solid #b9befa;
}
.settings-sidebar .sidebar-body .theme-wrapper .theme-item img {
  width: 100%;
  border-radius: 3px;
}
.settings-sidebar .sidebar-body .theme-wrapper .theme-item:hover::after {
  background: rgba(114, 124, 245, 0.2);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.sidebar-folded .sidebar .sidebar-header {
  width: 70px;
}
.sidebar-folded .sidebar .sidebar-header .sidebar-brand {
  display: none;
}

.sidebar-folded .page-wrapper {
  width: calc(100% - 70px);
  margin-left: 70px;
}
.sidebar-folded .page-wrapper .navbar {
  width: calc(100% - 70px);
  left: 70px;
  right: 0;
}
.navbar {
  background: #0c1427 !important;
  border-bottom: 1px solid #344258;
  border-right: 1px solid #344258;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar {
  width: 70px;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-header {
  width: 70px;
}
.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-header
  .sidebar-brand {
  opacity: 0;
  visibility: hidden;
  width: 0;
}
.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link
  .link-title,
.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link
  .link-arrow {
  visibility: hidden;
  opacity: 0;
}
.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item.nav-category {
  visibility: hidden;
}
.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item.nav-category::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #9b9b9b;
  position: absolute;
  top: 5px;
  left: 6px;
  visibility: visible;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body .nav.sub-menu {
  display: none;
}

@media (max-width: 991px) {
  .sidebar-open .main-wrapper::before,
  .settings-open .main-wrapper::before {
    content: "";
    width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    -webkit-transition: all 3s ease;
    transition: all 3s ease;
    z-index: 980;
  }
}

.rtl {
  direction: rtl;
  text-align: right;
}
.rtl .main-wrapper .sidebar {
  right: 0;
  left: auto;
}
@media (max-width: 991px) {
  .rtl .main-wrapper .sidebar {
    margin-right: -240px;
  }
  .sidebar-open.rtl .main-wrapper .sidebar {
    margin-right: 0;
  }
}
.rtl .main-wrapper .sidebar .sidebar-header {
  border-right: 0;
  border-left: 1px solid #f2f4f9;
}
.sidebar-dark.rtl .main-wrapper .sidebar .sidebar-header {
  border-left: 1px solid #344258;
}
.rtl .main-wrapper .sidebar .sidebar-body {
  direction: ltr;
}
.rtl .main-wrapper .sidebar .sidebar-body .nav {
  width: 100%;
}
@media (min-width: 992px) {
  .rtl .main-wrapper .sidebar .sidebar-body .nav {
    direction: rtl;
  }
}
.rtl .main-wrapper .sidebar .sidebar-body .nav .nav-item {
  width: 100%;
  direction: ltr;
}
.rtl .main-wrapper .sidebar .sidebar-body .nav .nav-item .nav-link {
  direction: rtl;
}
.rtl .main-wrapper .sidebar .sidebar-body .nav .nav-item .nav-link .link-title {
  margin-left: 0;
  margin-right: 30px;
}
.rtl .main-wrapper .sidebar .sidebar-body .nav .nav-item .nav-link .link-arrow {
  margin-left: 0;
  margin-right: auto;
}
.rtl
  .main-wrapper
  .sidebar
  .sidebar-body
  .nav
  .nav-item.active
  .nav-link::before {
  right: -25px;
  left: auto;
}
.rtl .main-wrapper .sidebar .sidebar-body .nav .nav-item.nav-category::before {
  right: 6px;
}
.rtl .main-wrapper .sidebar .sidebar-body .nav.sub-menu {
  padding: 0 33px 15px 0;
}
.rtl
  .main-wrapper
  .sidebar
  .sidebar-body
  .nav.sub-menu
  .nav-item
  .nav-link::before {
  left: auto;
  right: -29px;
}
.rtl .main-wrapper .settings-sidebar {
  right: auto;
  left: -232px;
  border-radius: 0 0 4px 0;
  -webkit-box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
}
.settings-open.rtl .main-wrapper .settings-sidebar {
  right: auto;
  left: 0;
}
.rtl .main-wrapper .settings-sidebar .sidebar-body .settings-sidebar-toggler {
  left: auto;
  right: -44px;
  -webkit-box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  border-radius: 0 4px 4px 0;
}
@media (min-width: 992px) {
  .rtl .main-wrapper .page-wrapper {
    margin-left: 0;
    margin-right: 240px;
  }
  .sidebar-folded.rtl .main-wrapper .page-wrapper {
    margin-right: 70px;
  }
}
@media (min-width: 992px) {
  .rtl .main-wrapper .page-wrapper .navbar {
    left: 0;
    right: 240px;
    -webkit-transition: width 0.1s ease, right 0.1s ease;
    transition: width 0.1s ease, right 0.1s ease;
  }
  .sidebar-folded.rtl .main-wrapper .page-wrapper .navbar {
    right: 70px;
    width: calc(100% - 70px);
  }
}
.tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area {
  border-top: 1px solid #e8ebf1;
}
.tox.tox-tinymce
  .tox-editor-container
  .tox-sidebar-wrap
  .tox-edit-area
  .tox-edit-area__iframe {
  background-color: #fff;
}

// nav

.main-wrapper {
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
}
.main-wrapper .page-wrapper {
  min-height: 100vh;
  background: #f9fafb;
  width: calc(100% - 240px);
  margin-left: 240px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-transition: margin 0.1s ease, width 0.1s ease;
  transition: margin 0.1s ease, width 0.1s ease;
}
.main-wrapper .page-wrapper .page-content {
  flex-grow: 1;
  padding: 25px;
  margin-top: 0px;
}
@media (max-width: 767px) {
  .main-wrapper .page-wrapper .page-content {
    padding: 25px 15px;
  }
}
.main-wrapper .page-wrapper.full-page {
  width: 100%;
  margin-left: 0;
}
.main-wrapper .page-wrapper.full-page .page-content {
  margin-top: 0;
}
@media (max-width: 991px) {
  .main-wrapper .page-wrapper {
    margin-left: 0;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .rtl .main-wrapper .page-wrapper {
    margin-left: 0;
    margin-right: 240px;
  }
  .sidebar-folded.rtl .main-wrapper .page-wrapper {
    margin-right: 70px;
  }
}
@media (max-width: 991px) {
  .rtl .main-wrapper .page-wrapper {
    margin-right: 0;
    width: 100%;
  }
}
.rtl .main-wrapper .page-wrapper.full-page {
  margin-right: 0;
  margin-left: 0;
}
.rtl .main-wrapper .page-wrapper .navbar {
  -webkit-transition: width 0.1s ease, right 0.1s ease;
  transition: width 0.1s ease, right 0.1s ease;
}
@media (min-width: 992px) {
  .rtl .main-wrapper .page-wrapper .navbar {
    left: 0;
    right: 240px;
    -webkit-transition: width 0.1s ease, right 0.1s ease;
    transition: width 0.1s ease, right 0.1s ease;
  }
  .sidebar-folded.rtl .main-wrapper .page-wrapper .navbar {
    right: 70px;
    width: calc(100% - 70px);
  }
}
@media (max-width: 991px) {
  .rtl .main-wrapper .page-wrapper .navbar {
    right: 0;
  }
}
.rtl .main-wrapper .page-wrapper .navbar .navbar-content .search-form {
  margin-right: 0;
}
.rtl .main-wrapper .page-wrapper .navbar .navbar-content .navbar-nav {
  margin-left: 0;
  margin-right: auto;
}
.rtl
  .main-wrapper
  .page-wrapper
  .navbar
  .navbar-content
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu {
  right: auto;
  left: -20px;
}
@media (max-width: 991px) {
  .rtl
    .main-wrapper
    .page-wrapper
    .navbar
    .navbar-content
    .navbar-nav
    .nav-item.dropdown
    .dropdown-menu {
    left: 20px;
  }
}
.rtl
  .main-wrapper
  .page-wrapper
  .navbar
  .navbar-content
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu::before {
  right: auto;
  left: 28px;
}
.rtl
  .main-wrapper
  .page-wrapper
  .navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-body
  .profile-nav
  .nav-item
  .nav-link
  svg {
  margin-right: 0;
  margin-left: 20px;
}
.rtl
  .main-wrapper
  .page-wrapper
  .navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content {
  margin-left: 0;
  margin-right: 15px;
  text-align: right;
}
.rtl
  .main-wrapper
  .page-wrapper
  .navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content {
  margin-left: 0;
  margin-right: 15px;
  text-align: right;
}
.rtl .main-wrapper .page-wrapper .page-content .main-content p {
  direction: ltr;
}
.rtl .main-wrapper .page-wrapper .page-content .main-content .highlight pre {
  direction: ltr;
}
.rtl .main-wrapper .page-wrapper .page-content .content-nav-wrapper {
  right: auto;
  left: 0;
  border-left: 0;
  border-right: 1px solid #e8ebf1;
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}
.dataTables_wrapper .data-table-extensions-filter input {
  color: #fff;
}
.jNIkof {
  background-color: #070d19 !important;
}
.jNIkof div {
  color: white !important;
}

@media (min-width: 1650px) {
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
}
.banner-bg-2 {
  background-image: url(../images/banner_bg02.jpg);
  background-position: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.modal {
  display: block;
}
.text-white p {
  color: white;
}
.rdt_TableHeadRow {
  background-color: #0c1427 !important;
  border-bottom-color: #0c1427 !important;
  color: #727cf5 !important;
}
.rdt_TableRow {
  background-color: #0c1427 !important;
  border-color: #ffffff26 !important;
  color: #eef4ff !important;
  font-size: 15px !important;
  margin: 3px;
}
.rdt_TableRow:hover {
  outline-color: #0c1427 !important;
  background-color: rgb(7, 13, 25) !important;
}
.rdt_Table {
  background-color: #0c1427 !important;
  border-bottom-color: #070d19 !important;
}
.rdt_Pagination {
  background-color: #0c1427 !important;
  color: #727cf5 !important;
  border-bottom-color: #070d19 !important;
  border-top-color: #070d19 !important;
}
.dataTables_wrapper .data-table-extensions-filter .icon {
  margin-top: 5px;
}
.data-table-extensions > .data-table-extensions-filter > .filter-text {
  border: 1px solid !important;
  border-color: rgba(255, 255, 255, 0.27) !important;
  border-radius: 5px !important;
  padding: 4px 10px !important;
}
.modal.show {
  display: block !important;
  background-color: rgba(0, 0, 0, 0.79);
  transition: 0.2s ease;
}
.fileDropzone {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  min-height: 200px;
  background-color: #0c1427;
  border: 2px dashed #268bd2;
  text-align: center;
  h6 {
    color: #8d8c8c;
  }
  p {
    color: #919191;
    font-size: 14px;
  }
  &:hover {
    background-color: #070d19;
    cursor: pointer;
  }
}
.countdownTimer div svg path:first-child {
  stroke: rgb(33, 33, 33);
}
.card-title-main {
  font-size: 15px;
  text-transform: none;
  color: #eef4ff;
  font-weight: 500;
  margin: 15px 0;
}

.notificationCard {
  background-color: rgba(19, 20, 41, 0.88);
  border: 2px solid;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
}
.notificationMessg {
  text-transform: none;
  font-size: 15px;
}
.notificationTitle {
  font-size: 14px;
  color: rgb(232, 230, 230);
}
.notificationCard.danger {
  border-color: #ff3366;
  .notificationMessg {
    color: #ff3366;
  }
}
.notificationCard.success {
  border-color: #10b759;
  .notificationMessg {
    color: #0ddd68;
  }
}
.notificationCard.warning {
  border-color: #fbbc06;
  .notificationMessg {
    color: rgba(245, 198, 80, 0.99);
  }
}

input:autofill,
input:-webkit-autofill-strong-password,
input:-webkit-autofill-strong-password-viewable,
input:-webkit-autofill-and-obscured {
  -webkit-text-fill-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background-color: #333 !important;
  box-shadow: 0 0 0 #fff !important;
  color: #fff !important;
}

.popover {
  position: absolute;
  z-index: 1060;
  left: 190px;
  display: block;
  max-width: 176px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  text-transform: none;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #030b15;
  border: 1px solid #10b759;
  border-radius: 0.3rem;
  padding: 0px 10px;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover  p {
  margin-bottom: 7px!important;
  margin-top: 6px!important;
  color: #10b759;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.icon-menu-mobil-media{
  display: none!important;
}
.stat-mobil-version{
  display: none!important;
}
.navbar-mobil{
  display: none!important;
}

// media

@media (max-width: 900px) {
  .navbar .search-form{
    display: inline !important;
    font-size: 15px !important;
    margin-top: 14px;
  }
  .navbar-content{
    width: 100%!important;
  }
  .icon-menu-mobil-media{
    display: inline!important;
  }
  .media-mt-3{
    margin-top: 25px!important;
  }
  .title.media-fs-17{
    font-size: 15px!important;
  }
  .media-fs-17{
    font-size: 15px!important;
  }
  .stat-web-version{
    display: none!important;
  }
  .stat-mobil-version{
    display: block!important;
  }
  .navbar-mobil{
    display: block!important;
  }
  .media-d-none{
    display: none!important;
  }
  .media-fs-10{
    font-size: 12px!important;
  }
  .title.media-fs-20{
    font-size: 18px!important;
  }
  .media-btn-sm{
    font-size: 10px!important;
    padding: 8px 15px!important;
  }
  .data-table-extensions-filter .filter-text{
    width: 180px!important;
  }
}
.bg-activ {
  background-color: #0a1f10;
}

.closeexportlist {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.3;
  transition: 0.3s ease;
}

.closeexportlist:hover {
  cursor: pointer;
  opacity: 1;
  transition: 0.3s ease;
}

.fs-18 {
  font-size: 18px !important;
  line-height: 20px !important;
}

::-webkit-scrollbar {
  background-color: #000;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
      180deg,
      rgb(60, 61, 61) 0%,
      rgb(87, 88, 88) 100%

    ),
    linear-gradient(to left, #d9d9d9, #d9d9d9);
  border-radius: 20px;
}

.btn2 {
  font-size: 14px;
  line-height: 1;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border-radius: 0.1875rem;
  box-shadow: none;
  border: none;
}

.table-hover.summary tbody tr:hover > * {
  background-color: rgb(255 255 255 / 2%);
  color: #FFFFFF;
}

.hover-pointer {
  cursor: pointer;
}

.hover-pointer:hover {
  color: #10b759;
}

.btn.custom-btn {
  padding: 5px 15px 10px 15px;
  font-size: 12px;
  background: #155222;
  border-radius: 10px;
}

.btn.custom-btn:hover {
  cursor: pointer;
  border-color: #0ddd68;
  color: #0ddd68;
}
.btn.custom-btn .icon {
  font-size: 19px;
  position: relative;
  top: 2px;
}

.rounded-xl {
  border-radius: 10px;
}

.processing:disabled, .processing:disabled:hover {
  background-color: #495057 !important;
  cursor: not-allowed;
}